import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { wordpress as WordpressActions } from "../../services/Wordpress/WordpressActions"

const tagsLanguage = {
  pt: 167,
  es: 183
}

const PreviewBlog = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { articles } = useSelector(state => state.wordpress)
  const { languageCurrent } = useSelector(state => state.language)
  const [firstArticle, setFirstArticle] = useState()

  useEffect(() => {
    if (languageCurrent){
      dispatch(
        WordpressActions.getArticles({
          per_page: 5,
          order: "desc",
          orderby: "modified",
          _embed: true,
          page: 1,
          tags: tagsLanguage[languageCurrent]
        })
      )
    }
  }, [languageCurrent])

  useEffect(() => {
    if (articles) setFirstArticle(articles[0])
  }, [articles])

  const redirectArticle = url => window.open(url, "_blank")

  return (
    <div className="PreviewBlog">
      <div className="PreviewBlog__articles">
        {firstArticle && (
          <div
            className="PreviewBlog__articles--article"
            style={{
              backgroundImage: `linear-gradient(88.87deg, rgb(0 0 0 / 53%) 20.69%, rgb(0 0 0 / 51%) 98.83%)${firstArticle._embedded["wp:featuredmedia"]? ', url(${firstArticle._embedded["wp:featuredmedia"][0].source_url})': ''}`,
            }}
          >
            <h1 className="title">{firstArticle.title.rendered}</h1>
            <div className="body">
              <p>{firstArticle.acf.descripcion_corta}</p>
              <div className="cont-btn">
                <Button
                  className="btn btn--demo"
                  onClick={() => redirectArticle(firstArticle.link)}
                >
                  {t("button.read_article")}
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="PreviewBlog__articles--menu">
          <h3 className="menu-title">{t("general.may_also_like")}</h3>
          {articles?.slice(1).map((article, index) => (
            <div
              key={index}
              className="hand item-inter"
              onClick={() => redirectArticle(article.link)}
            >
              <p>{index + 1}.</p>
              <span>{article.title.rendered}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default PreviewBlog
