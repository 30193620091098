import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "antd"

import play from "../../assets/images/play.png"
import { navigatePage } from "../../@common/utils/navigate"
import SEO from "../../components/seo"
import LayoutPublic from "../../components/Layout/LayoutPublic/LayoutPublic"
import SectionTabs from "../../components/SectionTabs/SectionTabs"
import { SectionStrategies } from "../../components/PageHome/SectionStrategies/SectionStrategies"
import { Banner } from "../../components/BannerMaps/BannerMaps"
import { SectionLogistics } from "../../components/PageHome/SectionLogistics/SectionLogistics"
import { SmartConnection } from "../../components/SmartConnection/SmartConnection"
import { SectionTrustDeliveries } from "../../components/PageHome/SectionTrustDeliveries/SectionTrustDeliveries"
import FollowUs from "../../components/FollowUs/FollowUs"
import SectionWeTalkYou from "../../components/PageHome/SectionWeTalkYou/SectionWeTalkYou"
import PreviewBlog from "../../components/PreviewBlog/PreviewBlog"

const Home = ({ data: { contentfulPaginaHome: contentfulPageHome } }) => {
  const { t } = useTranslation()
  const { languageISO639 } = useSelector(state => state.language)

  if (!contentfulPageHome) return null

  const {
    seo,
    banner_title,
    banner_video,
    button_lms_demo,
    button_quote,
    i_want_lifter,
    training,
    benefits,
    technologies,
    systematization,
    proyection,
    traceability,
    title_liftit_connection,
    description_liftit_connection,
    servicios_api
  } = contentfulPageHome


  contentfulPageHome.systematization.animation =
    "animation--two animation--two--true"
  contentfulPageHome.proyection.animation = "like like--true"
  contentfulPageHome.traceability.animation =
    "animation--three animation--two--true"

  return (
    <LayoutPublic>
      <SEO {...seo} lang={languageISO639} />
      <div className="Home">
        <Banner
          title={banner_title}
          multimedia={banner_video}
          interval={5000}
          button1={
            <Button
              className="btn btn--primary"
              onClick={() => navigatePage("/revolutionizes-deliveries")}
            >
              {t("button.revolutionize_deliveries")}
            </Button>
          }
          button2={
            <Button className="btn btn--demo" onClick={() => navigatePage("/home-demo")}>
              <img className="btn__img" src={play} alt="img-play" />
              {t("button.see_demo")}
            </Button>
          }
        />
        <SectionStrategies {...contentfulPageHome} section="sectionThree" />

        <SectionTabs
          {...contentfulPageHome}
          typeMultimedia="carousel"
          typeImages="background"
          index={0}
          sectionTitle="LMS"
          sectionTabsProps={"sectionOne"}
          tabsKeys={["systematization", "proyection", "traceability"]}
          items={[systematization, proyection, traceability]}
          buttons={[
            /*<Button key={1} className="btn btn--primary">
              {button_quote?.label}
            </Button>,*/

            <Button key={0} className="btn btn--demo" onClick={() => navigatePage("/home-demo")}>
              <img className="btn__img" src={play} alt="img-play" />
              {t("button.see_demo")}
            </Button>
          ]}
        />

        <SectionLogistics media={button_quote.photo} {...contentfulPageHome} />
        <SectionTabs
          {...contentfulPageHome}
          index={1}
          footerMap
          typeMultimedia="image_static"
          itemsInLines={[t("general.compliance"), t("general.excellence")]}
          sectionTitle={t("general.community_lifters")}
          tabsKeys={["training", "benefits", "technologies"]}
          items={[training, benefits, technologies]}
          sectionTabsProps={"sectionTwo"}
          backgroundRight="background-black"
          buttons={[
            <Button
              key={0}
              className="btn btn--primary"
              onClick={() => navigatePage("/lifters")}
            >
              {i_want_lifter?.label}
            </Button>,
          ]}
        />
        <SmartConnection
          title={title_liftit_connection}
          description={description_liftit_connection?.description_liftit_connection}
          servicesApi={servicios_api}
        />
        <SectionTrustDeliveries {...contentfulPageHome} />
        <div className="content-blog_we-talk-you">
          <SectionWeTalkYou {...contentfulPageHome} />
          <PreviewBlog />
        </div>
        <div className="page-lifter__followus">
          <FollowUs />
        </div>
      </div>
    </LayoutPublic>
  )
}

export const pageQuery = graphql`
  query PageHome($locale: String) {
    contentfulPaginaHome(language: { language: { eq: $locale } }) {
      seo {
        ...SEOFragment
      }
      banner_video {
        src
      }
      banner_title
      we_connection {
        ...SectionFragment
      }
      we_plan {
        ...SectionFragment
      }
      we_inspire {
        ...SectionFragment
      }
      we_execute {
        ...SectionFragment
      }
      systematization {
        ...SectionFragment
      }
      proyection {
        ...SectionFragment
      }
      traceability {
        ...SectionFragment
      }
      button_lms_demo {
        ...LinkFragment
      }
      button_quote {
        ...LinkFragment
      }
      title_simple_logistics
      description_simple_logistics {
        description_simple_logistics
      }
      title_allies
      allies {
        photo
        label
        link {
          link
        }
      }
      button_simple_logistics {
        ...LinkFragment
      }
      training {
        ...SectionFragment
      }
      benefits {
        ...SectionFragment
      }
      technologies {
        ...SectionFragment
      }
      title_liftit_connection
      description_liftit_connection {
        description_liftit_connection
      }
      full_documentation {
        ...LinkFragment
      }
      servicios_api {
        name
        method_http
        url
        description {
          json
        }
        header {
          internal {
            content
          }
        }
        params {
          key
          description
          required
          type
        }
        response {
          internal {
            content
          }
        }
      }
      title_cases_success {
        h1
        h2
      }
      success_stories {
        name
        subtitle
        logo
        logo_secondary
        banner
        image_bluer
        description {
          description
        }
        link {
          ...LinkFragment
        }
      }
      i_am_options {
        key
        name
        i_want {
          key
          name
        }
      }
      i_want_lifter {
        ...LinkFragment
      }
    }
  }
`

Home.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
}

export default Home
